import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.devstage';

@Injectable({
  providedIn: 'root',
})
export class ApiCrudService {
  // Variables
  crudUrl = environment.apiUrl + 'api/crud/';
  /**
   * Constructor
   * @param http The http client object
   */
  constructor(private http: HttpClient) {}

  public read<T = any>(url: string, id?: number, query: string = '') {
    let readUrl: string;
    if (id) {
      readUrl = this.crudUrl + url + '/' + id + query;
    } else {
      readUrl = this.crudUrl + url + query;
    }

    return this.http.get<T>(readUrl, { responseType: 'json' });
  }
  public create<T = any, V = any>(url: string, data: V) {
    return this.http.post<T>(this.crudUrl + url, data, {
      responseType: 'json',
    });
  }
  public update<T = any, V = any>(url: string, id: number, data: V) {
    return this.http.put<T>(this.crudUrl + url + '/' + id, data, {
      responseType: 'json',
    });
  }
  public delete(url: string, id: number) {
    return this.http.delete(this.crudUrl + url + '/' + id, {
      responseType: 'json',
    });
  }
}
