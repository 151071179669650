import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard  {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedPermission = route.data.expectedPermission;
    expectedPermission.push('all');

    const permissions: string[] =
      JSON.parse(localStorage.getItem('Permissions') || '').permissions || [];

    //check if the two arrays share a permission
    const found = expectedPermission.some(
      (r: any) => permissions.indexOf(r) >= 0
    );

    if (!this.auth.hasToken() || !found) {
      notify('False Permission', 'error', 5000);
      //this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  hasPermission(permissionName: string[]) {
    // this will be called manually by a component

    //always include check for the all permission

    const expectedPermission = permissionName;
    expectedPermission.push('all');
    const permissions: string[] =
      JSON.parse(localStorage.getItem('Permissions') || '').permissions || [];

    //check if the two arrays share a permission
    const found = expectedPermission.some(
      (r: any) => permissions.indexOf(r) >= 0
    );

    if (!this.auth.hasToken() || !found) {
      return false;
    }
    return true;
  }
}
